@import './../../common/styles/CommonStyles';

#menu {
  display: grid;
  grid-template-columns: repeat(5, max-content) auto max-content;
  background-image: url('./images/menuBackground.png');
  color: $color-default-font-menu;
  height: $height-top-menu;
  align-items: center;
  -webkit-transition: all .4s ease;
  -moz-transition: all .4s ease;
  -o-transition: all .4s ease;
  animation: animate-menu-darken 6s ease-in-out 0s none, animate-menu-background 200s linear infinite;
  padding-left: 1rem;
  padding-right: 1rem;
}

.menu-default-dark {
  filter: brightness(50%);
}

.menu-no-default-dark {
  filter: brightness(100%);
}

#current-incident-collection-tab {
  filter: brightness(100%);
}

#menu:hover {
  filter: brightness(100%);
}

@keyframes animate-menu-darken {
  0% {
    filter: brightness(100%);
  }
  80% {
      filter: brightness(100%);
    }
  100% {
    filter: brightness(50%);
  }
}

@keyframes animate-menu-background {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 125rem 0;
  }
 }

#logo-wrapper{
  grid-column: 1;
}

#status-command-wrapper{
  grid-column: 2;
}

#settings-command-wrapper{
  grid-column: 3;
}

#domain-selector-wrapper {
  grid-column: 4;
  display: block;
}

#incident-source-selector-wrapper {
  grid-column: 5;
  display: block;
}

#current-incident-collection-tab-wrapper {
  grid-column: 6;
  display: flex;
  justify-content: right;
  align-self: flex-end;
  height: 70%;
}

#user-wrapper{
  grid-column: 7;
  min-width: 20rem;
  justify-content: right;
}   

@media (max-width: 1400px) {
  #user-wrapper{
    display: none;
  }
}

@media (max-width: 1000px) {
  #domain-selector-wrapper{
    display: none;
  }
  #incident-source-selector-wrapper{
    display: none;
  }
}