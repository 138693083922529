@import './../../../common/styles/CommonStyles.scss';

#domain-video-channel {
    grid-column: 2;
    display: flex;
    width: 100%;
    height: max-content;
    aspect-ratio: 9/16;

    #domain-video-channel-iframe {
        width: 100%;
        aspect-ratio: 9/16
    }
}