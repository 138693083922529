@import './../../common/styles/CommonStyles';

#incident-collection {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-rows: $height-active-result-top-menu auto;
}

#incident-list-column-header-wrapper {
    grid-row: 1;
}

#incident-list-wrapper {
    grid-row: 2;
    overflow-y: auto;
}