@import './../../../common/styles/CommonStyles';

#current-incident-collection-tab {
    
    align-items: center;
    padding-left: 1rem;
    padding-right: .8rem;
    background: $background-current-collection-tab;
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
    font-size: .9rem;
    -moz-box-shadow: inset 0 -.4rem .4rem -.4rem $background-incident-collection-shadow;
    -webkit-box-shadow: inset 0 -.4rem .4rem -.4rem $background-incident-collection-shadow;
    box-shadow: inset 0 -.4rem .4rem -.4rem $background-incident-collection-shadow;

}

.current-incident-collection-tab-visible {
    display: flex;
}

.current-incident-collection-tab-hidden {
    display: none;
}

#action-menu {
    background: $background-current-collection-tab;
    border-style: none;
    height: 2rem;
}

#incident-collection-caption {
    margin-right: .6rem;
    margin-top: .2rem;
}

#incident-collection-name {
    font-weight: bold;
    white-space: nowrap;
    margin-right: 1rem;
    margin-top: .2rem;
}

#close-incident-collection{
    width: fit-content;
    height: fit-content;
}

#close-incident-collection-image{
    width: 1rem;
    height: 1rem;
    align-self: center;
    cursor: pointer;
}

@media (max-width: 800px) {
    #incident-collection-caption{
        display: none;
    }
}

@media (max-width: 700px) {
    #incident-collection-name{
        display: none;
    }
}