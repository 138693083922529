@import './../../../../../../common/styles/CommonStyles';


.incident-match-result-excerpts {
    display: block;
    height: $height-incident-match-result-entity-match-map-canvas;
    background: $background-incident-match-result-details;
    grid-template-columns: auto;
    overflow: auto;
    padding-top: .5rem;
}

.excerpt {
    font-size: .9rem;
    grid-column: 1;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: .3rem;
    padding-bottom: .3rem;
}

mark {
    background: $color-match-result-highlight;
    font-family: $font-match-result;
    color: $color-default-font-incident-match-result;
    font-weight: bold;
    padding-left: .4rem;
    padding-right: .4rem;
    border-radius: .5rem;
}

mark:hover {
    background: $color-match-result-highlight-in-focus;
    border-style: solid;
    border-width: .05rem;;
    border-color: black;    
    cursor: pointer;
    padding-left: .5rem;
    padding-right: .5rem;
}

.blink-location {
    animation: blink-location-animation 1s;
}

@keyframes blink-location-animation {
    0% {color: black} 
    50% {color: $color-match-result-details-location-blink-highlight} 
    100% {color: black} }


.text-wrapped {
    word-wrap: break-word;
}

@media (max-width: 900px) {
    .location{
        width: 10rem !important;
    }
}

@media (max-width: 350px) {
    .location{
        width: 5rem !important;
    }
}
@media (max-width: 550px) {
    .location{
        width: 10rem !important;
    }
}

@media (max-width: 350px) {
    .location{
        width: 5rem !important;
    }
}