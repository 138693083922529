@import './../../../../../common/styles/CommonStyles';

.incident-match-result {
    grid-template-columns: max-content max-content auto;
    height: $height-incident-match-result-entity-match-map-canvas;
    grid-gap: 1px;
}

.match-result-visible {
    display: grid;
}

.match-result-hidden {
    display: none;
}

.incident-match-result-summary-wrapper {
    grid-column: 1;
    width: $height-incident-match-result-entity-match-map-canvas
}

.incident-match-result-entity-match-map-wrapper {
    grid-column: 2;
    display: flex;
}

.incident-match-result-details-wrapper {
    grid-column: 3;
}


@media (max-width: 650px) {
    .incident-match-result-summary-wrapper{
        display: none;
    }
}