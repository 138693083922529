@import './../../../common/styles/CommonStyles';

#status-command {
    display: flex;
    height: 2.0rem;
    border-radius: 35%;
    width: 2.4rem;
    align-items: center;
    justify-self: right;
    justify-content: center;
    font-weight: bold;
    font-size: .8rem;
    background: $background-incident-overview-status-5;
    cursor: pointer;
}


