@import './../../common/styles/CommonStyles';

#overview-sections {
    height: 100%;
    width: 100%;
    display: block;
    overflow-y: auto;
    color: $color-default-font-overview-section;
    border-top-style: solid;
    border-top-color: $border-after-menu;
    border-top-width: 1px;
    border-right-style: solid;
    border-right-color: $border-after-menu;
    border-right-width: 1px;
}


#overview-sections > :not(:last-child) {
    margin-bottom: .2rem;
}
