@import './../../../common/styles/CommonStyles';

#logo {
    width: max-content;
}

#logo-image {
    height: 2.2rem;
    cursor: pointer;
}

#logo-no-text-image {
    height: 2.2rem;
    display: none;
}

@media (max-width: $width-incident-overview-small-device-limit) {
    #logo-image {
        display: none;   
    }
    #logo-no-text-image {
        display: block; 
    }
}