@import './../../../../../../common/styles/CommonStyles';



.incident-match-result-entity-match-map {
    width: $width-incident-match-result-entity-match-map-canvas;
    height: $height-incident-match-result-entity-match-map-canvas;
}

.incident-match-result-entity-match-map:hover {
    background: $background-entity-match-map-selected;
    border-style: solid;
    border-width: 1px;
    border-color: $background-entity-match-map-border-color-selected; 
    cursor: pointer;
}


.incident-match-result-entity-match-map-even {
    background: $background-entity-match-map-even;
}

.incident-match-result-entity-match-map-odd {
    background: $background-entity-match-map-odd;
}

