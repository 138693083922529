@import './../../../common/styles/CommonStyles';

.incident-cancellation-content {
    width: 17rem;
    align-items: center;
    cursor: pointer;

    :hover {
        background: $background-incident-in-focus-overlay;
    }
}

.menu-item {
    font-size: 1rem;
    padding-left: 1rem;
    padding-top: .3rem;
    padding-bottom: .6rem;
    padding-right: .8rem;
    cursor: pointer;
}

.reason {
    font-size: 1rem;
    cursor: pointer;
}

.reason-remark {
    font-weight: bolder;
    height: 3.4rem;
    cursor: pointer;
}

.reason-remark-input {
    font-size: 1rem;
    height: 2.6rem;
    width: 60%;
    padding-left: .6rem;
    padding-right: .6rem;
    border-radius: .8rem;
    display: inline-block; 
}

.reason-remark-button-container {
    width: 30%;
    margin-left: .5rem;
    display: inline-block; 
}

.menu-item:first-child {
    padding-top: .6rem;
}

.menu-item:last-child {
    padding-bottom: .6rem;
}

.menu-item:hover {
    background: $background-context-menu;
}
