@import './../../../../../common/styles/CommonStyles';

.manual-handling-incident-type-row {
    display: grid;
    grid-template-columns: 1rem auto 8rem;
    grid-template-rows: max-content 3px;    
    grid-column-gap: .5rem;
    grid-row-gap: .15rem;
    align-items: top;
    font-size: .9rem;
    cursor: pointer;
}

.manual-handling-incident-type-severity-level {
    grid-column: 1;
    grid-row: 1;
    display: flex;
    align-items: top;
    padding-top: .1rem;
}

.manual-handling-incident-type-severity-level-image {
    height: 1rem;
}

.manual-handling-incident-type-title {
    grid-column: 2;
    grid-row: 1;
    display: flex;
    align-items: top;
    justify-content: left;
    font-weight: bold;
}

.manual-handling-incident-type-count {
    grid-column: 3;
    grid-row: 1;
    display: flex;
    align-items: top;
    justify-content: right;
}

.manual-handling-incident-type-comparison-bar {
    grid-column: 1 / span 3;
    grid-row: 2;
}