@import './../../../../common/styles/CommonStyles';


#manual-handling-incidents {
    display: grid;
    grid-template-rows: max-content max-content;
    grid-row-gap: 0.4rem;
}

#manual-handling-incident-types {
    grid-column: 1;
    display: grid;
    grid-template-rows: max-content;
    grid-row-gap: .7rem;
    color: $color-default-font-overview-section;
    padding: .8rem;
}

#manual-handling-incident-severityLevels {
    grid-column: 1;
    display: grid;
}

#manual-handling-incident-severityLevel3 {
    grid-row: 2;
    grid-column: 1;
    background: $background-dampen-severity-level-3;
    border-bottom-color: $background-color-severity-level-3;
    cursor: pointer;
}

#manual-handling-incident-severityLevel2 {
    grid-row: 2;
    grid-column: 2;
    background: $background-dampen-severity-level-2;
    border-bottom-color: $background-color-severity-level-2;
    cursor: pointer;
}

#manual-handling-incident-severityLevel1 {
    grid-row: 2;
    grid-column: 3;
    background: $background-dampen-severity-level-1;
    border-bottom-color: $background-color-severity-level-1;
    cursor: pointer;
}

.manual-handling-incident-severityLevel {
    display: flex;
    height: 1.8rem;
    justify-content: center;  
    font-weight: bold;
    color: $color-default-font-overview-section;
    border-top-width: .1rem;
    border-bottom-style: solid;
    border-bottom-width: .3rem;

}
