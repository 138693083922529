@import './../../../common/styles/CommonStyles';

#domain-selection-drop-down-button {
  background-color: $background-top-menu-domain-selector-drop-down-button;
}

#domain-selector {
  width: fit-content;
}

.btn-secondary {
  --bs-btn-bg: $background-top-menu-button;
}

.dropdown-menu {
}


