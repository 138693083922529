@import './../../../common/styles/CommonStyles';

#incident-decision-maker-clue-filter-name-selector {
}

#incident-decision-maker-clue-filter-name-selection-drop-down-button {
  width: fit-content;
  background-color: $background-top-menu-incident-source-filter-name-selector-drop-down-button;
}

.btn-secondary {
  --bs-btn-bg: $background-top-menu-button;
}

.dropdown-menu {
}