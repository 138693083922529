@import './../../../../../common/styles/CommonStyles';

.incident-list-context-menu {
    align-items: center;
    cursor: pointer;

    :hover {
        background: $background-context-menu;
    }
}

.incident-list-context-menu-image {
    height: 1.5rem;
    border-radius: 50%;
}

.incident-list-context-menu-popover {
    width: $width-general-context-menu;
}

.incident-list-context-menu-popover-header {
    font-weight: bold;
    font-size: .9rem;
    background: $background-menu-current-incident-collection-tab;
    color: $color-default-font-menu;
}

.incident-list-context-menu-popover-body {
    padding: 0;
    margin: 0;
}

.incident-list-context-menu-popover-body-menu-item {
    font-size: 1rem;
    padding-left: 1rem;
    padding-top: .3rem;
    padding-bottom: .6rem;
    padding-right: .8rem;
    cursor: pointer;
}

.incident-list-context-menu-popover-body-menu-item:first-child {
    padding-top: .6rem;
}

.incident-list-context-menu-popover-body-menu-item:last-child {
    padding-bottom: .6rem;
}

.incident-list-context-menu-popover-body-menu-item:hover {
    background: $background-context-menu;
}

.incident-cancellation-context-menu-part-container {
    padding-left: .6rem;
}

.highlight-context-menu {
    animation: highlight-context-menu 1.5s;
}

@keyframes highlight-context-menu {
    0% {background: transparent}
    25% {background: $background-incident-selection-context-menu-button-highlight;}
    50% {background: transparent}
    75% {background: $background-incident-selection-context-menu-button-highlight;}
    100% {background: transparent}
}

  