@import './../../../../../../common/styles/CommonStyles';

.incident-match-result-summary {
    display: flex;
    background-image: url('./images/summaryBackground.png');
    background-size: contain;
    height: $height-incident-match-result-entity-match-map-canvas;
    font-size: .9rem;
    text-align: center;
    align-items: center;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    padding-top: .2rem;
    padding-bottom: .2rem;
}

.occurrence-caption {
    font-size: .9rem;
    font-weight: bold;
}

.occurrence-section-item {
    font-size: .8rem;
}
