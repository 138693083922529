@import './../../../../common/styles/CommonStyles';

.standard-action-button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: $color-default-font-overview-section;
    font-weight: bolder;
    font-size: .8rem;
    border-radius: .8rem;
    border-width: 4px;
    height: 100%;
    padding: .5rem 0.4rem 0.4rem 0.4rem;
}

.standard-action-button-title {
    font-weight: bold;    
}


.enabled {
    background: $background-standard-button;
    border-style: solid;
    border-color: $background-standard-button;
}

.enabled:hover {
    cursor: pointer;
    border-color: $color-border-standard-action-button-focus;
}

.disabled {
    background: $background-standard-button-disabled;
    border-style: none;
}
 

.disabled:hover {
   border-color: $background-standard-button-disabled;
}