@import './../../../common/styles/CommonStyles';

#automatic-handling-recommendations {
    color: $color-default-font-overview-section;
    padding: .8rem;
}

#automatic-handling-recommendations-list {
    display: grid;
    grid-template-rows: max-content;
    grid-template-columns: auto 5.5rem;
    grid-gap: 1.2rem;
    padding: .2rem;
}

.automatic-handling-recommendation-selected-checkbox {
    grid-column: 1;
    display: flex;
    align-items: flex-start;

    .form-check-label {
        margin-left: .5rem;
        font-size: .9rem;
        font-weight: bold;
    }

    .form-check-input {
        border-style: none;
        width: 2rem;
        height: .8rem;
        margin-bottom: .2rem;
    }

}

.automatic-handling-recommendation-show-incidents {
    grid-column: 2;
    height: max-content;
    border-radius: .4rem;
    background: $background-show-incidents-button;
    padding: .2rem;
    padding-bottom: .1rem;
    font-size: .9rem;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    
}


#automatic-handling-recommendation-no-recommendations {
    font-size: .9rem;
    text-align: center;
}

#automatic-handling-recommendations-action-wrapper {
    margin-top: 1rem;
    text-align: center; 
}

