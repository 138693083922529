@import './../../../../common/styles/CommonStyles';

.standard-search-button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: $background-standard-button;
    color: $color-default-font-overview-section;
    font-weight: bolder;
    font-size: .9rem;
    border-radius: .8rem;
    border-color: $background-standard-button;
    border-style: solid;
    border-width: 4px;
    padding: .8rem;
}

.standard-search-button:hover {
    cursor: pointer;
    border-color: $color-border-standard-search-button-focus;
}

.standard-search-button-title {
    font-weight: bold;    
}

