@import './../../common/styles/CommonStyles';

#customer-presentation {
    display: grid;
    grid-template-rows: 50% 50%;
    height: 100%;
    width: 100%;

    #welcome {
        grid-row: 1;
        position: relative;
        display: grid;
        grid-template-rows: 40% 20% 40%;
        grid-row-gap: 1%;
        justify-items: center;
        width: 100%;
        height: 100%;
        padding-left: 10%;
        padding-right: 10%;
        padding-bottom: 2%;

        #background-image {
            display: flex;
            position: absolute;
            height: 100%;
            width: 100%;
            object-fit: cover;
            z-index: 0;
            opacity: 60%;
        }

        #logo-image {
            grid-row: 1;
            display: flex;
            background-color: $background-customer-presentation-logo;
            padding: 1.8rem;
            height: 100%;
            width: auto;
            border-bottom-left-radius: 3vmin;
            border-bottom-right-radius: 3vmin;
            opacity: 90%;
        }
        
        #governance-catch-phrase {
            grid-row: 2;
            display: flex;
            width: 100%;
            height: 100%;
            text-align: center;
            justify-content: center;
            align-items: end;
            font-size: calc(100vw / 60);
            font-weight: bold;
            color: $color-font-customer-presentation;
            z-index: 10;
        }
        
        #overall-purpose {
            grid-row: 3;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            text-align: center;
            align-items: center;
            font-size: calc(100vw / 70);
            color: $color-font-customer-presentation;
            z-index: 11;
        }
    }
   
    #details {
        grid-row: 2;
        display: flex;
        width: 100%;
        height: 100%;
        padding: 5%;

        #support-entities {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            
            #support-entities-headline {
                display: flex;
                flex-direction: row;
                justify-content: center;
                text-align: center;
                align-items: end;
                width: 100%;
                height: 20%;
                font-size: 3vmin;
                color: $color-font-customer-presentation;
            }

            #support-entities-list {
                overflow-y: auto;
                flex-wrap: wrap;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
                width: 100%;
                height: 80%;

                .support-entity {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: max-content;
                    height: max-content;
                    padding: 2%;
                    color: $color-font-customer-presentation;
                    border-radius: 2vmin;
                    padding: 1vmin;
                    transition: 0.5s;

                    .support-entity-field {
                        display: flex;
                        width: max-content;
                        height: max-content;
                        padding: 2%;
                    }
                    .support-entity-name {
                        font-weight: bold;
                        font-size: 1.8vmin;
                    }

                    .support-entity-role-name {
                        font-size: 2vmin;
                    }

                    .support-entity-contact-initiation {
                        margin-top: 1vmin; 
                        padding: 1vmin;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        width: 9vmin;
                        aspect-ratio: 1/1;
                        background: $background-support-entity-contact-initiation;
                    }

                    a {
                        text-align: center;
                        width: 100%;
                        text-decoration: none;
                        font-weight: bold;
                        font-size: 1.6vmin;
                        color: $color-support-entity-contact-initiation;
                    }

                }

                .support-entity:hover {
                    background-color: $background-customer-presentation-support-entities;
                    scale: 1.02;
                    transition: background-color .5s ease-in-out;
                    transition: scale .5s ease-in-out;


                }
            }
        }
    }
}







