@import './../../../common/styles/CommonStyles';

.overview-section { 
    display: grid;
    grid-template-rows: $height-overview-section-header auto;

    .header{
        display: grid;
        background: $background-overview-section-header;
        grid-template-columns: auto $didth-overview-section-header-details;
        color: $color-default-font-overview-section;
        font-weight: bold;
        padding-top: .1rem;
        padding-bottom: .1rem;
        padding-right: .6rem;
        padding-left: .6rem;
        cursor: pointer;

        
        .header-title {
            display: flex;
            font-variant-caps: all-petite-caps;
            align-items: center;
        }

        .header-details {
            display: flex;
            justify-content: right;
            align-self: center;
            font-size: smaller;
            color: $color-default-font-overview-section-details;
            font-weight: 800;
            height: 60%;
        }
    }

    .header:hover {
        background: $background-overview-section-header-hover;
    }
    
    .section-content-wrapper {
        background: $background-overview-section-content;
        border-bottom-left-radius: .2rem;
        border-bottom-right-radius: .2rem;
    }
    
    .section-content-visible {
        display: block;
    }
    
    .section-content-hidden {
        display: none;
    }
}
