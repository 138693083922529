$color-default-font: black;
$color-default-font-dark: rgb(50, 50, 50);
$color-default-font-light-dark: rgb(100, 100, 100);
$color-default-font-light: rgb(200, 200, 200);
$background-incident-list-additional-value: rgb(0, 128, 128, 0.15);
$background-site: rgb(68, 84, 106);

$background-customer-presentation: rgb(48, 59, 75);
$background-customer-presentation-support-entities: rgb(63, 77, 97);
$background-customer-presentation-shadow: rgb(42, 52, 66);
$background-menu-current-incident-collection-tab: rgb(234, 132, 15);

$background-support-entity-contact-initiation: rgb(108, 124, 146);
$color-support-entity-contact-initiation: white;

$color-font-customer-presentation: white;
$background-customer-presentation-logo: white;
$shadow-general: rgb(90, 90, 90);
$color-default-font-menu: white;

$color-default-font-incident-collection-column-header-sort-active: rgb(201, 136, 62);

$background-show-incidents-button:  #198754;
$background-domain-selector: rgb(108, 124, 146);
$background-domain-selector-tab: rgb(88, 104, 126);
$background-domain-selector-tab-selected: rgb(68, 84, 106);
$background-domain-incident-overview: rgb(68, 84, 106);
$border-after-menu: rgb(80, 95, 114);
$background-top-menu-button: rgb(255, 255, 255, 0.1);

$background-top-menu-domain-selector-drop-down-button: rgba(123, 255, 0, 0.1);
$background-top-menu-incident-source-filter-name-selector-drop-down-button: rgba(110, 214, 255, 0.2);
$background-incident-secondary-decision-maker-clue-location: rgba(100, 204, 245, 0.416);

$color-default-font-incident-list-column-header: rgb(215, 220, 228);
$background-incident-overview-shallow-panel-symbol: rgb(108, 124, 146);
$background-current-collection-tab: rgb(47, 61, 79);
$background-incident-collection: rgb(47, 61, 79);
$background-incident-list-column-header: rgb(47, 61, 79);
$background-incident-list: white;
$background-incident-type-group-unselected: rgb(108, 124, 146);
$background-incident-type-group-selected: rgb(81, 92, 107);
$background-incident-in-focus: rgb(198, 216, 232);
$background-incident-in-focus-overlay: rgb(147, 169, 197);
$border-color-incident-selected: rgb(167, 167, 167);
$color-default-font-incident-type-group: white;
$color-default-font-incident: black;
$background-manual-handling-text-search-input: white;
$background-manual-handling-text-search-input-simple: rgb(187, 196, 209);
$color-default-font-incident-match-result: black;
$color-match-result-highlight: rgb(255, 205, 42);
$color-match-result-highlight-in-focus: rgb(253, 224, 128);
$background-match-result-details-column-headers: rgb(195, 200, 204);
$color-default-font-match-result-details-column-headers: black;
$background-incident-match-result-details: rgb(244, 244, 244);
$background-entity-match-map-even: rgb(252, 224, 135);
$background-entity-match-map-odd: rgb(250, 217, 109);
$background-entity-match-map-selected: rgb(255, 251, 231);
$background-entity-match-map-border-color-selected: rgb(255, 251, 231);
$color-match-result-details-location-blink-highlight: rgb(167, 135, 29);
$background-overview-section-header: linear-gradient(rgb(40, 51, 66) 0, rgb(31, 41, 54) 100%);
$background-overview-section-header-hover: rgb(31, 41, 54);
$background-overview-section-content: rgb(63, 75, 94);
$color-default-font-overview-section: rgb(234, 238, 243);
$color-default-font-overview-section-element: rgb(234, 238, 243);
$color-default-font-overview-section-details: rgb(165, 180, 199);
$background-standard-button: rgb(48, 60, 77);
$background-standard-button-disabled: rgb(139, 139, 139);
$color-border-standard-search-button-focus: rgb(71, 128, 71);
$color-border-standard-action-button-focus: rgb(201, 136, 62);
$color-background-general-context-menu: rgb(255, 205, 42);
$color-background-general-context-menu-checkbox: rgb(255, 205, 42);
$background-status-detailed-analysis-comparison-bar: linear-gradient(90deg, #598b4c, rgb(182, 182, 182),  #8d4343);
$color-border-incident-url-link-button: #6C7C92;
$background-incident-url-link-button: linear-gradient(45deg, #cbcdcf 20%, #b5c0cf 50% , #bac4d1 70%, #dfe4eb );
$color-default-font-status-detailed-analysis-comparison-object: white;
$color-border-status-detailed-analysis-comparison-bar: rgb(192, 192, 192);
$background-status-detailed-analysis-comparison-object-primary: rgb(64, 64, 64);
$color-border-status-detailed-analysis-comparison-object-primary: rgb(88, 88, 88);
$background-status-detailed-analysis-comparison-object-secondary: rgb(80, 80, 80);
$color-border-status-detailed-analysis-comparison-object-secondary: rgb(111, 111, 111);
$background-status-detailed-analysis-standard-kpi-graph: rgb(48, 60, 77);
$background-status-detailed-analysis-standard-kpi-graph-latest-period: rgb(45, 56, 71);
$color-base-highlight-incident-overview-status: white;
$color-base-angle-incident-overview-status: 45deg;
$percentage-color-shift-1-incident-overview-status: 0%;
$percentage-color-shift-2-incident-overview-status: 72%;
$percentage-color-shift-3-incident-overview-status: 94%;
$color-base-incident-overview-status-1: rgba(255,0,0,1);
$background-incident-overview-status-1: linear-gradient($color-base-angle-incident-overview-status, $color-base-incident-overview-status-1 $percentage-color-shift-1-incident-overview-status, $color-base-incident-overview-status-1 $percentage-color-shift-2-incident-overview-status, $color-base-highlight-incident-overview-status $percentage-color-shift-3-incident-overview-status);
$color-base-incident-overview-status-2: rgba(255,0,0,1);
$background-incident-overview-status-2: linear-gradient($color-base-angle-incident-overview-status, $color-base-incident-overview-status-2 $percentage-color-shift-1-incident-overview-status, $color-base-incident-overview-status-2 $percentage-color-shift-2-incident-overview-status, $color-base-highlight-incident-overview-status $percentage-color-shift-2-incident-overview-status);
$color-base-incident-overview-status-3: rgba(255,0,0,1);
$background-incident-overview-status-3: linear-gradient($color-base-angle-incident-overview-status, $color-base-incident-overview-status-3 $percentage-color-shift-1-incident-overview-status, $color-base-incident-overview-status-3 $percentage-color-shift-2-incident-overview-status, $color-base-highlight-incident-overview-status $percentage-color-shift-3-incident-overview-status);
$color-base-incident-overview-status-4: rgba(255,0,0,1);
$background-incident-overview-status-4: linear-gradient($color-base-angle-incident-overview-status, $color-base-incident-overview-status-4 $percentage-color-shift-1-incident-overview-status, $color-base-incident-overview-status-4 $percentage-color-shift-2-incident-overview-status, $color-base-highlight-incident-overview-status $percentage-color-shift-3-incident-overview-status);
$color-base-incident-overview-status-5: rgba(160,160,0,1);
$background-incident-overview-status-5: linear-gradient($color-base-angle-incident-overview-status, $color-base-incident-overview-status-5 $percentage-color-shift-1-incident-overview-status, $color-base-incident-overview-status-5 $percentage-color-shift-2-incident-overview-status, $color-base-highlight-incident-overview-status $percentage-color-shift-3-incident-overview-status);
$color-base-incident-overview-status-6: rgba(255,0,0,1);
$background-incident-overview-status-6: linear-gradient($color-base-angle-incident-overview-status, $color-base-incident-overview-status-6 $percentage-color-shift-1-incident-overview-status, $color-base-incident-overview-status-6 $percentage-color-shift-2-incident-overview-status, $color-base-highlight-incident-overview-status $percentage-color-shift-3-incident-overview-status);
$color-base-incident-overview-status-7: rgba(255,0,0,1);
$background-incident-overview-status-7: linear-gradient($color-base-angle-incident-overview-status, $color-base-incident-overview-status-7 $percentage-color-shift-1-incident-overview-status, $color-base-incident-overview-status-7 $percentage-color-shift-2-incident-overview-status, $color-base-highlight-incident-overview-status $percentage-color-shift-3-incident-overview-status);
$color-base-incident-overview-status-8: rgba(255,0,0,1);
$background-incident-overview-status-8: linear-gradient($color-base-angle-incident-overview-status, $color-base-incident-overview-status-8 $percentage-color-shift-1-incident-overview-status, $color-base-incident-overview-status-8 $percentage-color-shift-2-incident-overview-status, $color-base-highlight-incident-overview-status $percentage-color-shift-3-incident-overview-status);
$color-base-incident-overview-status-9: rgba(255,0,0,1);
$background-incident-overview-status-9: linear-gradient($color-base-angle-incident-overview-status, $color-base-incident-overview-status-9 $percentage-color-shift-1-incident-overview-status, $color-base-incident-overview-status-9 $percentage-color-shift-2-incident-overview-status, $color-base-highlight-incident-overview-status $percentage-color-shift-3-incident-overview-status);
$color-base-incident-overview-status-10: rgba(255,0,0,1);
$background-incident-overview-status-10: linear-gradient($color-base-angle-incident-overview-status, $color-base-incident-overview-status-10 $percentage-color-shift-1-incident-overview-status, $color-base-incident-overview-status-10 $percentage-color-shift-2-incident-overview-status, $color-base-highlight-incident-overview-status $percentage-color-shift-3-incident-overview-status);
$background-color-severity-level-3: rgb(255, 0, 0);
$background-color-severity-level-2: rgb(255, 153, 0);
$background-color-severity-level-1: rgb(255, 255, 0);
$background-dampen-severity-level-3: rgba(255, 0, 0, 0.2);
$background-dampen-severity-level-2: rgba(255, 153, 0, 0.2);
$background-dampen-severity-level-1: rgba(255, 255, 0, 0.2);
$background-context-menu: rgb(200, 200, 200);
$background-incident-selection-context-menu-button-highlight: rgb(255, 205, 42);
$background-incident-overview-scrollbar-track: rgb(82, 100, 124);
$background-incident-overview-scrollbar-thumb: rgb(58, 70, 88);
$background-incident-overview-scrollbar-thumb-hover: rgb(23, 27, 34);
$background-incident-collection-shadow: rgb(31, 41, 54);
$background-overview-wrapper-shallow-panel-normal: rgb(32, 40, 51);
$background-overview-wrapper-shallow-panel-hover: rgb(44, 55, 70);
$backkground-entity-incident-under-removal: rgb(238, 192, 192);