@import './../../../common/styles/CommonStyles';

#settings-command {
    height: 1.5rem;
}

#settings-command-image {
    height: 100%;
    cursor: pointer;
}

#settings-minimize-incident-overview-checkbox {
    align-items: flex-start;

    .form-check-label {
        margin-left: .5rem;
        font-size: .9rem;
        font-weight: bold;
    }

    .form-check-input {
        border-style: none;
        width: 2rem;
        height: .8rem;
        margin-bottom: .2rem;
    }

}

#settings-context-menu-popover {
    width: $width-settings-context-menu;
}

.settings-context-menu-popover-header {
    font-weight: bold;
    font-size: .9rem;
    background: $background-menu-current-incident-collection-tab;
    color: $color-default-font-menu;
}

.settings-context-menu-popover-body {
    padding: 0;
    margin: 0;
}

.settings-context-menu-popover-body-menu-item {
    font-size: 1rem;
    padding-left: 1rem;
    padding-top: .3rem;
    padding-bottom: .3rem;
    cursor: pointer;
}

.settings-context-menu-popover-body-menu-item:first-child {
    padding-top: .6rem;
}

.settings-context-menu-popover-body-menu-item:last-child {
    padding-bottom: .6rem;
}

.settings-context-menu-popover-body-menu-item:hover {
    background: $background-context-menu;
}

.highlight-context-menu {
    animation: highlight-context-menu 1.5s;
}

@keyframes highlight-context-menu {
    0% {background: transparent;}
    25% {background: $background-incident-selection-context-menu-button-highlight;}
    50% {background: transparent;}
    75% {background: $background-incident-selection-context-menu-button-highlight;}
    100% {background: transparent;}
}

