@import './../../../../common/styles/CommonStyles';

.incident-item {
    height: fit-content;
    display: grid;
    grid-template-columns: 1rem 1rem 30% auto repeat(2, max-content);
    grid-gap: 1rem;
    align-items: center;
    padding-left: .7rem;
    padding-right: 1rem;
    cursor: pointer;
    font-size: .9rem;
}

.context-menu-wrapper {
    grid-column: 1;
}

.entity-incident-is-under-removal {
    background-color: $backkground-entity-incident-under-removal;
}

.selected-checkbox {
    grid-column: 2;

    .form-check-input {
        opacity: .5;
        border-color: $border-color-incident-selected;
    }

    .form-check-input:hover {
        opacity: 1;
    }
}

.entity-title {
    grid-column: 3;

    span.line {
        display: inline-block;
    }

    .entity-title-access-link {
        color: $color-default-font;
        font-weight: bold;
        text-decoration: none;
    }

    .entity-title-main-line {
        margin-right: .4rem;
        font-weight: bold;
        overflow-wrap: anywhere;
    }

    .friendly-id-additional-value {
        display: inline;
        font-weight: bold;
        font-size: 1rem;
        color: $color-default-font-light-dark;
        padding-top: .1rem;
        padding-left: .4rem;
        padding-right: .4rem;
        background: $background-incident-list-additional-value;
        border-radius: .4rem;
    }
}

.entity-friendly-container{
    grid-column: 4;

    span.line {
        display: inline-block;
    }
    
    .entity-friendly-container-access-link {
        color: $color-default-font;
        font-weight: bold;
        text-decoration: none;
    }
    
    .entity-friendly-container-secondary-decision-maker-clue-line {
        margin-right: .4rem;
        padding-top: .2rem;
        padding-bottom: .1rem;
        padding-left: .4rem;
        padding-right: .4rem;
        font-weight: 600;
        overflow-wrap: anywhere;
        background-color: $background-incident-secondary-decision-maker-clue-location;
        border-radius: .3rem;
    }

    .entity-friendly-container-main-line {
        margin-right: .4rem;
        font-weight: bold;
        overflow-wrap: anywhere;
    }


}

.entity-default-timestamp{
    grid-column: 5;
    width: 8rem;
}

.preview-match-result-wrapper {
    grid-column: 6;
    display: flex;
    width: 8rem;
    height: max-content;
    justify-content: right;
    align-items: center;
    
    .preview-match-result-highlight-frame {
        height: 1.8rem;
        padding-top: .2rem;
        padding-left: .7rem;
        padding-right: .5rem;
        background: $color-match-result-highlight;
        font-family: $font-match-result;
        border-radius: .5rem;
        font-size: 1rem;

        .preview-match-result {
            display: inline-flex;
            text-align: center;
            color: $color-default-font-incident-match-result;
            white-space: nowrap;

            .preview-match-result-more-items {
                display: inline-flex;
                margin-left: .4rem;
                font-family: $font-match-normal;
                font-weight: normal;
            }
        }
    }
}

.preview-match-result:hover {
    background: $color-match-result-highlight-in-focus;
    font-weight: bold;
    cursor: pointer;
}


.incident-item-cell {
    padding-top: .5rem;
    padding-bottom: .5rem;
    font-weight: bold;
}

.text-wrapped {
    word-wrap: break-word;
}

.gap-to-next-small{
    margin-right: .5rem;
}

.incident-item:hover {
    background: $background-incident-in-focus;
}

@media (max-width: 1300px) {

    .preview-match-result-wrapper {
        width: 1rem;
    }

    .preview-match-result-wrapper > .preview-match-result-highlight-frame > .preview-match-result {
        display: none;
        white-space: nowrap;
        overflow: hidden;
    }     
}

@media (max-width: 1100px) {
    .entity-default-timestamp{
        display: none;
    }
}
