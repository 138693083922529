@import './../../../../common/styles/CommonStyles';

.incident-type-group {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(5, max-content) auto;
    color: $color-default-font-incident-type-group;
    font-weight: bold;
    align-items: center;
}

.gap-to-next{
    margin-right: 1rem;
}

.gap-to-next-small {
    margin-right: .5rem;
}

.accordion-button {
    padding-left: 2.7rem;
}

.incident-type-group-selected-checkbox {
    grid-column: 2;
    size: .8rem, .8rem;
    display: flex;
    align-items: center;
    margin-top: -.25rem;

    .form-check-input{
        opacity: .5;
    }

    .form-check-input:hover {
        opacity: 1;
    }
}

.severity-level {
    grid-column: 3;
    display: flex;
    align-items: center;

    .severity-level-image {
        height: 1.2rem;
    }
}

.group-name {
    grid-column: 4;
    align-items: center;
    padding-top: 1px;
}

.count-label {
    grid-column: 5;
    font-weight: normal;
    padding-top: -3px;
}

.selection-status {
    grid-column: 6;
    font-weight: bold;
    text-align: left;
    padding-top: .05rem;
}

@media (max-width: 600px) {
    .name{
        width: 8rem;
    }
}

@media (max-width: 400px) {
    .name{
        width: 4rem;
    }
    .count-label{
        display: none;
    }
    .selection-status{
        display: none;
    }
}
