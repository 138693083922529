@import './../../../../../../common/styles/CommonStyles';

.status-detailed-analysis-comparison-bar {
    display: grid;
    font-weight: bold;
    grid-template-columns: 100%;
    grid-template-rows: 1.5rem 1.5rem;
    grid-row-gap: .5rem;
    grid-column-gap: .8rem;
}

.title {
    display: flex;
    grid-column: 1 / span 2;
    grid-row: 1; 
}

.bar-wrapper {

    align-self: center;

    .bar {
        display: flex;
        grid-column: 1;
        grid-row: 2;
        position: relative;
        height: .5rem;
        border-color: $color-border-status-detailed-analysis-comparison-bar;
        border-style: solid;
        border-width: .1rem;
        border-radius: .2rem;
        background: $background-status-detailed-analysis-comparison-bar;
            
        .compare-object {
            display: flex;
            position: absolute;
            font-size: .8rem;
            font-weight: bold;
            top: 50%;
            transform: translate(-50%, -50%);
            justify-content: center;
            padding-left: .5rem;
            padding-right: .5rem;
            height: 1.6rem;
            color: $color-default-font-status-detailed-analysis-comparison-object;
            width: max-content;
            border-style: solid;
            border-width: 2px;
        }

        .primary-object {
            z-index: 1;
            background: $background-status-detailed-analysis-comparison-object-primary;
            border-color: $color-border-status-detailed-analysis-comparison-object-primary;
            border-radius: .7rem;
        }

        .secondary-object {
            z-index: 0;  
            background: $background-status-detailed-analysis-comparison-object-secondary;
            border-color: $color-border-status-detailed-analysis-comparison-object-secondary;
            border-radius: .2rem;
        }
    }
}
