$height-top-menu: 3.6rem;
$height-active-result-top-menu: 2.5rem;
$width-side-panel: 15rem;
$height-incident-match-result-entity-match-map-canvas: 200px;
$width-incident-match-result-entity-match-map-canvas: 12px;
$didth-overview-section-header-details: 8rem;

$width-general-context-menu: 18rem;
$width-settings-context-menu: 14rem;

$width-incident-overview:  27rem;
$width-incident-overview-shallow: 1rem;

$width-incident-overview-small-device-limit: 580px;

$height-overview-section-header: 2.4rem;

$height-incident-collection-column-header-sort-direction-symbol: .5rem;
$height-incident-collection-overview-shallow-panel-symbol: .5rem;
