@import './../../../common/styles/CommonStyles';

#incident-list {
    background: $background-incident-list;
    height: 100%;
}

.incident-type-group {
    color: $color-default-font-incident-type-group;
    font-weight: bold;
}
.accordion-header {
    border-style: none;
}

.accordion {
    --bs-accordion-border-radius: 0;
    --bs-accordion-inner-border-radius: 0;
    --bs-accordion-border-width: 1px;
    --bs-accordion-btn-focus-box-shadow: none;
    --bs-accordion-body-padding-x: 0;
    --bs-accordion-body-padding-y: 0;
}

.accordion-button:focus {
    background: $background-incident-type-group-selected !important;
}

.accordion-button {
    background: $background-incident-type-group-unselected !important;
}

.accordion-button::after {
    background-image: var(--bs-accordion-btn-icon);
}

.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon);
}



