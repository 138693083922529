@import './common/styles/CommonStyles';


#application {
    display: grid;
    grid-template-rows: max-content auto;
    width: 100vw;
    height: 100vh;
}

#menu-wrapper {
    grid-row: 1;
    height: $height-top-menu;
    z-index: 2;
}

#content-wrapper {
    grid-row: 2;
    display: grid;
    grid-template-columns: max-content auto;
    top: $height-top-menu;
    z-index: 1;
}

// TODO: We need to set height or the overflow-y on the sub-component will blow out. This is irritating. The same on active-result.
#overview-wrapper {
    grid-column: 1;
    height: calc(100vh - $height-top-menu);
}

#overview-wrapper-content {
    width: 100%;
    height: 100%;
}

.overview-wrapper-shallow {
    width: $width-incident-overview-shallow;
    transition: width .1s ease-in-out;
    #overview-wrapper-content {
        display: none;
    }

    #overview-wrapper-shallow-panel {
        display: flex;
    }
}

#overview-wrapper-shallow-panel {
    display: none;
    width: 100%;
    height: 100%;
    background: $background-overview-wrapper-shallow-panel-normal;

}

#overview-wrapper-shallow-panel-symbol {
    align-self: center;
    content: '';
    width: 0; 
    height: 0;
    margin-left: .2rem;
    border-left: $height-incident-collection-overview-shallow-panel-symbol solid $background-incident-overview-shallow-panel-symbol;
    border-top: $height-incident-collection-overview-shallow-panel-symbol solid transparent;
    border-bottom: $height-incident-collection-overview-shallow-panel-symbol solid transparent;
}

#overview-wrapper-shallow-panel:hover {
    background: $background-overview-wrapper-shallow-panel-hover;
    cursor: pointer
}

.overview-wrapper-normal {
    display: flex;
    width: $width-incident-overview;
}

#active-result-wrapper {
    grid-column: 2;
    width: 100%;
    height: calc(100vh - $height-top-menu);
}

#customer-presentation-wrapper {
    background: $background-customer-presentation;
    width: 100%;
    height: 100%;
}

#incident-collection-wrapper  {
    height: 100%;
}

::-webkit-scrollbar {
    width: .6rem;
}

::-webkit-scrollbar-track {
    background: $background-incident-overview-scrollbar-track;
}

::-webkit-scrollbar-thumb {
    background: $background-incident-overview-scrollbar-thumb;
}

::-webkit-scrollbar-thumb:hover {
    background: $background-incident-overview-scrollbar-thumb-hover;
}
