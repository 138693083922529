@import './../../../../common/styles/CommonStyles';

#incident-list-column-headers {
    height: $height-active-result-top-menu;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1rem 1rem 30% auto repeat(2, max-content);
    align-items: center;
    padding-left: .7rem;
    padding-right: 1rem;
    color: $color-default-font-incident-list-column-header;
    font-weight: bold;
    font-size: .9rem;
    cursor: pointer;
    border-top-style: solid;
    border-top-color: $border-after-menu;
    border-top-width: 1px;
    z-index: 1;
}


#incident-list-column-headers-context-menu-wrapper{
    grid-column: 1;

}

#incident-list-column-headers-selected-checkbox{
    grid-column: 2;
    padding-top: .1rem;
    margin-left: .125rem;

    #incident-list-column-headers-form-check-input {    
        opacity: .5;
    }

    #incident-list-column-headers-form-check-input:hover {
        opacity: 1;
    }
}

#incident-list-column-headers-entity-title{
    grid-column: 3;
}

.sort-column-header-cell-active {
    text-decoration-thickness: .15rem;
    text-decoration-line: underline;
}

.sort-column-header-cell-ascending::after {
    margin-left: .8rem; 
    content: '';
    width: 0; 
    height: 0;
    border-left: $height-incident-collection-column-header-sort-direction-symbol solid transparent;
    border-right: $height-incident-collection-column-header-sort-direction-symbol solid transparent;
    border-bottom: $height-incident-collection-column-header-sort-direction-symbol solid $color-default-font-incident-list-column-header;
}

.sort-column-header-cell-descending::after {
    margin-left: .8rem; 
    content: '';
    width: 0; 
    height: 0;
    border-left: $height-incident-collection-column-header-sort-direction-symbol solid transparent;
    border-right: $height-incident-collection-column-header-sort-direction-symbol solid transparent;
    border-top: $height-incident-collection-column-header-sort-direction-symbol solid $color-default-font-incident-list-column-header;
}

#incident-list-column-headers-entity-friendly-container{
    grid-column: 4;
}

#incident-list-column-headers-entity-default-timestamp{
    grid-column: 5;
    width: 8rem;
    padding-right: .2rem;
}

#incident-list-column-headers-preview-match-result{
    grid-column: 6;
    display: flex;
    padding-right: .3rem;
    justify-content: right;
    width: 8rem;
    color: $color-match-result-highlight;
}

.column-header-cell {
    padding-top: .1rem;
    display: flex;
    align-items: center;
    height: 80%;
}

.text-wrapped {
    word-wrap: break-word;
}

@media (max-width: 1300px) {

    #incident-list-column-headers-preview-match-result {
        width: 1rem;
    }
    
}

@media (max-width: 1100px) {
    #incident-list-column-headers-entity-default-timestamp{
        display: none;
    }
}