@import './../../../common/styles/CommonStyles';


#user-sign-in {
    width: 8rem;
    float: right;
}

#user-sign-in-button {
    height: 2.5rem;
}

#user-details {
    display: grid;
    align-items: center;
    grid-template-columns: auto 3.2rem;
    grid-template-rows: auto auto;
}

#user-name {
    grid-column: 1;
    grid-row: 1;
    text-align: right;
    font-size: .85rem;
    font-weight: bold;
    padding-top: .1rem;
}   

#user-organization-name {
    grid-column: 1;
    grid-row: 2;
    padding-top: 2px;
    text-align: right;
    font-size: .7rem;
}

#user-icon {
    height: 1.8rem;
    padding-left: 1rem;
    grid-column: 2;
    grid-row: 1 / span 2;
    cursor: pointer;
}

#user-icon-image {
    height: 100%;
}

