@import './../../../../common/styles/CommonStyles';

#manual-handling-shortcuts {
    
    padding: .2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(6rem, 1fr));
    grid-gap: .2rem;
    color: $color-default-font-overview-section;
    overflow: auto;
}


