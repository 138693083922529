@import './../../../../common/styles/CommonStyles';

#manual-handling-text-search {
    width: 100%;
    height: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: auto max-content;
    grid-column-gap: .4rem;
    padding: .8rem;
}

#manual-handling-text-search-input-normal {
    grid-column: 1;
    border-radius: .3rem;
    font-size: 1rem;
    width: 100%;
    padding-left: .4rem;
    background: $background-manual-handling-text-search-input;
}


#manual-handling-text-search-input-simple {
    text-align: center;
    border-radius: .6rem;
    font-size: .8rem;
    font-weight: 500;
    width: 9rem;
    height: 100%;
    background: $background-manual-handling-text-search-input-simple;
}

.manual-handling-text-search-input {
    border-style: solid;
    height: 100%;
  }

#manual-handling-shortcuts-text-search-action-button {
    grid-column: 2;
    height: 100%;
}
